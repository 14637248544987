
// const facebookAppId = process.env.VUE_APP_FACEBOOK_APP_ID
const facebookAppId = '829927717949154'

export function initFacebookSdk () {
  return new Promise(resolve => {
    // wait for facebook sdk to initialize before starting the vue app
    window.fbAsyncInit = function () {
      // eslint-disable-next-line no-undef
      FB.init({
        appId: facebookAppId,
        cookie: true,
        xfbml: true,
        version: 'v10.0'
      })
      // eslint-disable-next-line no-undef
      FB.AppEvents.logPageView()

      // load facebook sdk script
      ;(function (d, s, id) {
        var js; var fjs = d.getElementsByTagName(s)[0]
        if (d.getElementById(id)) { return }
        js = d.createElement(s); js.id = id
        js.src = 'https://connect.facebook.net/en_US/sdk.js'
        fjs.parentNode.insertBefore(js, fjs)
      }(document, 'script', 'facebook-jssdk'))
    }
  })
}
