import '@/assets/common.scss' // global css

import Vue from 'vue'
import { initFacebookSdk } from '@/facebook/init-facebook-sdk'

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

import App from './App.vue'
import router from './router'
import store from './store'

import '@/utils/loading' // 全局loading效果
import '@/utils/watermark' // 水印

Vue.use(ElementUI)

Vue.config.productionTip = false

initFacebookSdk()

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
